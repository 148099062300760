<template>
  <a-result
    v-if="isSuperAdmin"
    title="创建新工作空间"
    sub-title="请输入新工作空间的名称～"
  >
    <!--  -->
    <Logo v-if="!inline" slot="extra" />
    <!-- 图片 -->
    <span v-if="showAdvanceOption" slot="icon"></span>
    <img v-else slot="icon" :src="picurl" style="height: 260px" />
    <!-- 创建空间表单 -->
    <a-form
      slot="extra"
      layout="vertical"
      style="width: 380px; margin: 0 auto; text-align: left"
    >
      <a-form-item required :label="showAdvanceOption ? '空间名称' : ''">
        <a-input
          v-model.trim="spaceModel.name"
          placeholder="工作空间名称"
          size="large"
        />
      </a-form-item>
      <a-form-item v-if="showAdvanceOption" label="空间服务地址">
        <a-input-group compact>
          <a-input
            v-model="spaceModel.airbyteIp"
            style="width: 70%"
            placeholder="域名或IP地址"
            size="large"
          />
          <a-input
            v-model="spaceModel.airbytePort"
            style="width: 30%"
            placeholder="端口号"
            size="large"
          />
        </a-input-group>
      </a-form-item>
      <a-form-item>
        <a-button
          :disabled="!spaceModel.name"
          :loading="loading"
          type="primary"
          size="large"
          icon="plus"
          block
          @click="createSpace()"
        >
          创建
        </a-button>
      </a-form-item>
      <div v-if="!showAdvanceOption" style="text-align: center">
        <a @click="showAdvanceOption = true">
          更多选项
          <i class="ndl-icon-chevrons-down"></i>
        </a>
      </div>
    </a-form>
  </a-result>
  <a-result
    v-else
    status="404"
    title="暂未加入任何工作空间"
    sub-title="您没有创建工作空间的权限，请联系其他工作空间管理员邀请您加入"
    style="padding-top: 120px"
  >
    <a-button slot="extra" type="primary" @click="logout()">
      <i class="ndl-icon-log-out ndl-margin-right-sm"></i> 退出账号
    </a-button>
  </a-result>
</template>

<script>
import store from "@/store";
import { createSpace } from "@/api/space";
import picurl from "@/assets/images/ai_no_data.svg";
import Logo from "../auth/Logo";

export default {
  components: { Logo },
  props: {
    // inline 模式一般用于在 modal 中显示
    // inline: false 时一般是将此组件作为 route 的 component 选项
    inline: Boolean
  },
  data() {
    return {
      picurl,
      loading: false,
      showAdvanceOption: false,
      spaceModel: {
        name: "",
        airbyteIp: "",
        airbytePort: ""
      }
    };
  },
  computed: {
    isSuperAdmin() {
      return store.isSuperAdmin;
    }
  },
  methods: {
    createSpace() {
      this.loading = true;
      createSpace(this.spaceModel)
        .then(async ({ data }) => {
          this.$emit("on-created", data);
          this.loading = false;
          if (!this.inline) {
            await store.updateUserInfo();
          }
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.$emit("on-error", err);
          this.loading = false;
        });
    },
    logout() {
      store.logout();
      this.$router.push({ name: "login" });
    }
  }
};
</script>
