<template>
  <div class="ndl-padding-lg">
    <header class="ndl-text-heading ndl-flex-item--grow">修改密码</header>
    <a-form style="width: 350px; margin: 40px 60px">
      <a-radio-group v-model="form.comfirmType" button-style="solid">
        <a-radio-button value="mail">邮箱验证</a-radio-button>
        <a-radio-button value="password">当前密码验证</a-radio-button>
      </a-radio-group>
      <template v-if="form.comfirmType === 'mail'">
        <a-form-item label="邮箱">
          <a-input disabled :default-value="userInfo.mail" size="large">
            <i slot="prefix" class="ndl-icon-mail"></i>
          </a-input>
        </a-form-item>
        <a-form-item label="验证码" v-bind="decorator.code">
          <InputCaptcha
            v-model="form.code"
            :username="userInfo.mail"
            @input="$delete(decorator, 'code')"
          />
        </a-form-item>
      </template>
      <template v-else>
        <a-form-item label="当前密码" v-bind="decorator.oldPwd">
          <a-input-password
            v-model="form.oldPwd"
            size="large"
            placeholder="请输入当前密码"
            @change="$delete(decorator, 'oldPwd')"
          />
        </a-form-item>
      </template>
      <a-form-item label="新密码" v-bind="decorator.newPwd">
        <a-input-password
          v-model="form.newPwd"
          size="large"
          placeholder="请输入新密码"
          autocomplete="new-password"
          @change="$delete(decorator, 'newPwd')"
        />
      </a-form-item>
      <a-form-item label="确认新密码" v-bind="decorator.cmfPwd">
        <a-input-password
          v-model="form.cmfPwd"
          size="large"
          placeholder="请再次输入新密码"
          @change="$delete(decorator, 'cmfPwd')"
        />
      </a-form-item>
      <a-button
        :loading="loading"
        :disabled="!(form.newPwd && form.cmfPwd)"
        type="primary"
        size="large"
        block
        @click="updatePassword()"
      >
        修改密码
      </a-button>
    </a-form>
  </div>
</template>

<script>
import store from "@/store";
import InputCaptcha from "@/components/input-captcha";
import encrypt from "@/utils/encrypt";
import { updatePassword } from "@/api/user";

export default {
  components: { InputCaptcha },
  data() {
    return {
      form: this.getModel(),
      decorator: {},
      loading: false
    };
  },
  computed: {
    userInfo() {
      return store.userInfo;
    }
  },
  filters: {
    secret(str) {
      return str.replace(/^(\w{3})\w*(\w{4})$/, "$1****$2");
    }
  },
  methods: {
    getModel() {
      return {
        comfirmType: "mail",
        codeType: "mail",
        code: "",
        oldPwd: "",
        newPwd: "",
        cmfPwd: ""
      };
    },
    validate() {
      const form = this.form;
      const decorator = {};
      let valid = true;
      function assert(prop, help) {
        if (form[prop]) return;
        decorator[prop] = { help, validateStatus: "error" };
        valid = false;
      }
      const isByCode = form.comfirmType === "mail";
      assert(
        isByCode ? "code" : "oldPwd",
        isByCode ? "请输入验证码" : "请输入当前密码"
      );
      assert("newPwd", "请输入新密码");
      assert("cmfPwd", "请再次输入新密码");
      if (form.newPwd && form.cmfPwd && form.newPwd !== form.cmfPwd) {
        decorator.newPwd = decorator.cmfPwd = {
          help: "两次密码输入不一致",
          validateStatus: "error"
        };
        valid = false;
      }
      this.decorator = decorator;
      return valid;
    },
    updatePassword() {
      this.decorator = {};
      if (!this.validate()) return;
      this.loading = true;
      const { comfirmType, code, oldPwd, newPwd, cmfPwd } = this.form;
      const data =
        comfirmType === "mail"
          ? {
              code,
              codeType: "mail",
              newPwd: encrypt(newPwd),
              cmfPwd: encrypt(cmfPwd)
            }
          : {
              oldPwd: encrypt(oldPwd),
              newPwd: encrypt(newPwd),
              cmfPwd: encrypt(cmfPwd)
            };
      updatePassword(data)
        .then(() => {
          this.loading = false;
          this.$message.success("修改成功");
          this.form = this.getModel();
        })
        .catch(({ data }) => {
          this.loading = false;
          const decorator = {};
          const error = help => ({ help, validateStatus: "error" });
          switch (data.code) {
            // 验证码错误
            case "41001":
              decorator.code = error("验证码错误");
              break;
            // 验证码失效
            case "41002":
              decorator.code = error("验证码失效，请重新获取");
              break;
            // 当前密码错误
            case "42004":
              decorator.oldPwd = error("当前密码错误");
              break;
            // 未知错误
            case "40000":
              decorator.newPwd = decorator.cmfPwd = error("两次密码输入不一致");
              break;
            default:
              decorator.newPwd = decorator.cmfPwd = error(data.codeMsg);
              break;
          }
          this.decorator = decorator;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.ant-radio-group {
  display: flex;
  .ant-radio-button-wrapper {
    width: 0;
    flex-grow: 1;
    text-align: center;
  }
}
</style>
