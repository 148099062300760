<template>
  <div class="ndl-padding-lg">
    <header class="ndl-text-heading ndl-flex-item--grow">基本信息</header>
    <div class="meta-row ndl-flex ndl-flex--middle">
      <span class="meta-label ndl-text-desc">头像</span>
      <span class="meta-value">
        <Avatar
          circle
          :color="userInfo.memberId | colorOf"
          :src="userInfo.avatar"
          :text="userInfo.memberName"
        />
      </span>
      <a class="meta-edit" @click="editAvatar()">
        <i class="ndl-icon-edit"></i> 修改头像
      </a>
    </div>
    <div class="meta-row ndl-flex ndl-flex--middle">
      <span class="meta-label ndl-text-desc">个人昵称</span>
      <span class="meta-value">{{ userInfo.memberName }}</span>
      <a class="meta-edit" @click="editMemberName()">
        <i class="ndl-icon-edit"></i> 修改昵称
      </a>
    </div>
    <div class="meta-row ndl-flex ndl-flex--middle">
      <span class="meta-label ndl-text-desc">绑定手机号</span>
      <span class="meta-value">{{ userInfo.mobile || "-" }}</span>
      <a class="meta-edit" @click="editMobile()">
        <i class="ndl-icon-edit"></i> 更换手机号
      </a>
    </div>
    <div class="meta-row ndl-flex ndl-flex--middle">
      <span class="meta-label ndl-text-desc">绑定邮箱</span>
      <span class="meta-value">{{ userInfo.mail }}</span>
      <a class="meta-edit" @click="editMail()">
        <i class="ndl-icon-edit"></i> 更换邮箱
      </a>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import * as API from "@/api/user";
import Avatar, { colorOf } from "@/components/avatar";
import editName from "@/components/edit-name";
import selectImage from "@/components/cropper";

export default {
  components: { Avatar },
  computed: {
    userInfo() {
      return store.userInfo;
    }
  },
  filters: { colorOf },
  methods: {
    async editMemberName() {
      const newValue = await editName({
        title: "修改个人昵称",
        placeholder: "请输入新昵称",
        name: store.userInfo.memberName
      });
      if (newValue) {
        // 这个接口只能更新 nickname，不能更新 memberName
        // 历史原因，现在 memberName 恒等于 nickname
        // 因此更新 nickname 也能同时更新 memberName
        store.userInfo.memberName = newValue;
        store.userInfo.nickname = newValue;
        await API.updateUser(store.userInfo);
      }
    },
    async editMobile() {
      const newValue = await editName({
        title: "修改手机号码",
        placeholder: "请输入手机号码",
        name: store.userInfo.mobile
      });
      if (newValue) {
        store.userInfo.mobile = newValue;
        await API.updateUser(store.userInfo);
      }
    },
    async editMail() {
      const newValue = await editName({
        title: "修改邮箱",
        placeholder: "请输入邮箱",
        name: store.userInfo.mail
      });
      if (newValue) {
        store.userInfo.mail = newValue;
        await API.updateUser(store.userInfo);
      }
    },
    async editAvatar() {
      const userInfo = store.userInfo;
      const newImage = await selectImage({
        title: "设置头像",
        src: userInfo.avatar,
        text: userInfo.memberName,
        color: colorOf(userInfo.memberId),
        gallery: "avatar",
        circle: true
      });
      userInfo.avatar = newImage;
      await API.updateUser(userInfo);
    }
  }
};
</script>

<style lang="less" scoped>
.meta-row {
  margin: 3em 0;
  .meta-label {
    width: 150px;
    padding-left: 1em;
  }
  .meta-value {
    width: 300px;
  }
}
</style>
