import { render, staticRenderFns } from "./UserCenterOverview.vue?vue&type=template&id=587b7b72&scoped=true&"
import script from "./UserCenterOverview.vue?vue&type=script&lang=js&"
export * from "./UserCenterOverview.vue?vue&type=script&lang=js&"
import style0 from "./UserCenterOverview.vue?vue&type=style&index=0&id=587b7b72&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587b7b72",
  null
  
)

export default component.exports