<template>
  <section class="page-wrapper ndl-flex ndl-overflow">
    <ul class="sidebar-wrapper ndl-border-right ndl-flex-item--noshrink">
      <div class="ndl-padding">用户中心</div>
      <li
        v-for="item in menus"
        :key="item.view"
        :class="{ active: currentView === item.view }"
        @click="currentView = item.view"
      >
        {{ item.label }}
      </li>
    </ul>
    <div class="ndl-overflow ndl-flex-item--grow">
      <component :is="currentView" />
    </div>
  </section>
</template>

<script>
import overview from "./UserCenterOverview";
import account from "./UserCenterAccount";
import password from "./UserCenterPassword";

export default {
  components: { overview, account, password },
  data() {
    return {
      currentView: "overview",
      menus: [
        {
          label: "基本信息",
          view: "overview"
        },
        {
          label: "账号关联",
          view: "account"
        },
        {
          label: "修改密码",
          view: "password"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.page-wrapper {
  height: 100%;

  .sidebar-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
    width: @sidebar-width;
    overflow: auto;
  }
  .sidebar-wrapper li {
    line-height: 40px;
    padding-left: 40px;
    transition: color 0.2s, background-color 0.2s;
    cursor: pointer;
    &.active,
    &:hover {
      color: @primary-color;
      background-color: @primary-color-light;
    }
  }
}
</style>
